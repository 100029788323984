<template>
    <v-dialog
        v-model="addRegistrationNumberDialog"
        height="500"
        width="700"
    >
        <v-card>
            <v-toolbar
                flat
                dense
            >
                <v-toolbar-title v-if="!confirm">
                    {{ $t('addVehicle') }}
                </v-toolbar-title>
                <v-spacer />             
                <v-btn
                    icon
                    @click="addRegistrationNumberDialog = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text v-if="!confirm">
                <p>{{ $t('addVehicle-info') }}</p>

                <v-form v-model="valid">
                    <v-text-field
                        ref="registrationNumberTextfield"
                        v-model="registrationNumber"
                        :label="$t('attentiontype-0')"
                        :rules="globalCheckNotEmpty(registrationNumber)"
                        @input="registrationNumber = registrationNumber.toUpperCase()"
                    />
                </v-form>
                <v-btn
                    :disabled="!valid"
                    :loading="working"
                    @click="addRegistrationNumber()"
                >
                    {{ $t('customerportal-addRegistrationNumber') }}
                </v-btn>
            </v-card-text>

            <v-card-text v-if="confirm">
                <h2>{{ $t('registrations-model') }}: {{ vehicleBrand }} - {{ vehicleModel }}</h2>
                <h3 class="mb-4">
                    {{ $t('common/registrationNumber') }}: {{ registrationNumber }}
                </h3>
                <div
                    v-if="!vehicleNotFound"
                    class="mb-4"
                >
                    {{ $t('customerportal-addVehicle-info') }}
                </div>

                <v-checkbox
                    v-model="confirmVehicleCheckbox"
                    :label="$t('customerportal-addVehicle-confirmBox')"
                />
                <v-btn
                    :disabled="!confirmVehicleCheckbox"
                    :loading="working"
                    @click="confirmAddRegistrationNumber()"
                >
                    {{ $t('addRegistrationNumber') }}
                </v-btn>
            </v-card-text>
        </v-card>
        <error-dialog
            v-if="errorDialog"
            class="ma-0 pa-0"
            title-text="eksportstatus-30"
            :content-text="errorText"
            :content-sub-text="errorSubText"
            confirm-button-text="registrations-openLocationbutton-close"
            @confirm="errorDialog = false"
        />
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    components: {
        ErrorDialog: () => import('@/views/registration/ErrorDialog.vue')
    },
    data() {
        return {
            addRegistrationNumberDialog: false,
            registrationNumber: '',
            confirm: false,
            vehicleBrand: '',
            vehicleModel: '',
            confirmVehicleCheckbox: false,
            vehicleNotFound: true,
            valid: false,

            errorDialog: false,
            errorText: null,
            errorSubText: null,
            working: false
        }
    },
    computed: {
    },
    watch: {
        addRegistrationNumberDialog: function(val) {
            if (!val) {
                this.$emit('close')
            }
        },
        registrationNumber(value){
            this.registrationNumber = value.replace(/[^a-zA-Z0-9]/g, '')
        }
    },
    created() {        
        this.addRegistrationNumberDialog = true

        setTimeout(() => {
            this.$nextTick(() => {
                this.$refs.registrationNumberTextfield.$refs.input.focus()
            }), 500})
        
    },    
    methods: {
        addRegistrationNumber() {
            this.working = true
            this.FetchCustomerRegistrationDetails({
                pNumber: null,
                registrationNumber: this.registrationNumber
            }).then(result => {
                if(result.codes.find(x => x === 'registrationnumberhasexistingrelation')){
                    this.errorText = result.codes.find(x => x === 'registrationnumberhasexistingrelation')
                    if (this.errorText) {
                        this.errorText = this.$t('relationAlreadyExists')
                    }
                    this.working = false
                    this.errorDialog = true
                
                }else if(result.codes.find(x => x === 'registrationisrelatedtoacvrnumber')){
                    this.errorText = result.codes.find(x => x === 'registrationisrelatedtoacvrnumber')
                    if (this.errorText) {
                        this.errorText = this.$t('registrationisrelatedtoacvrnumber')
                    }
                    this.working = false
                    this.errorDialog = true
                } else if(result.codes.find(x => x === 'registrationnotfound')){
                    this.errorText = result.codes.find(x => x === 'registrationnotfound')
                    if (this.errorText) {
                        this.errorText = this.$t('registrationNotFound')
                    }
                    this.working = false
                    this.errorDialog = true
                }
                else if (result) {
                    this.vehicleBrand = result.vehicleBrand
                    this.vehicleModel = result.vehicleModel
                    if(this.vehicleBrand && this.vehicleModel) {
                        this.vehicleNotFound = false
                    }
                    this.confirm = true
                    this.working = false
                }
            })
        },
        confirmAddRegistrationNumber() {
            this.working = true
            this.FetchCreateCustomerPrivateRegistrationRelation({
                registrationNumber: this.registrationNumber,
                vehicleBrand: this.vehicleBrand,
                vehicleModel: this.vehicleModel,
                relationType: 0
            }).then(result => {
                if(result.codes.find(x => x === 'relationalreadypresentonothercustomer')){
                    this.errorText = result.codes.find(x => x === 'relationalreadypresentonothercustomer')
                    if (this.errorText) {
                        this.errorText = this.$t('relationAlreadyExists')
                    }
                    this.errorDialog = true
                    this.working = false
                }
                else {
                    this.addRegistrationNumberDialog = false
                }
            })
        },
        ...mapActions({
            FetchCustomerRegistrationDetails: 'FetchCustomerRegistrationDetails',
            FetchCreateCustomerPrivateRegistrationRelation: 'FetchCreateCustomerPrivateRegistrationRelation'
        })
    }
}
</script>